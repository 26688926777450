<template>
    <div id="contentcontainter" class="standard-mode">
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="showInfo">
            <v-icon>{{ info.icon }}</v-icon> <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <v-card class="mt-10 mx-auto quaroshadow rounded_card" max-width="1250px">
            <!-- toolbar start -->
            <v-toolbar color="primary" flat>
                <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                <v-toolbar-title color="white" style="color: white !important">
                    <v-row>
                        <v-col style="display: flex">
                            <!-- <v-icon color="white" class="my-auto">mdi-cog</v-icon> -->
                            <span class="tabheading">Settings</span>
                        </v-col>
                    </v-row>
                </v-toolbar-title>

                <template v-slot:extension>
                    <v-tabs v-model="tab" align-with-title color="white" dark class="white--text">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab v-for="item in items" :key="item.id" class="ml-0">
                            {{ item.text }}
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <!-- toolbar end -->

            <!-- tabs content -->
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in items" :key="item.id">
                    <v-card flat>
                        <v-card-text class="pa-0">
                            <UserTable :domains="domains" v-if="item.id == 1"></UserTable>
                            <CategorySettings v-else-if="item.id == 2"></CategorySettings>
                            <v-row v-else-if="item.id == 3" v-for="(domain, i) in domains" v-bind:key="i" class="mt-5 px-5">
                                <v-col md="11">
                                    <v-text-field
                                        label="Company domain"
                                        hint="New users registering with this domain will be automatically assigned to your database!"
                                        class="pr-5 pl-2"
                                        rounded
                                        filled
                                        persistent-hint
                                        dense
                                        type="url"
                                        v-model="domains[i]"></v-text-field>
                                </v-col>
                                <v-col md="1" style="display: flex">
                                    <v-btn color="primary" large @click="saveDomain(i)" class="mt-1" rounded>Save</v-btn>
                                </v-col>
                            </v-row>
                            <Credits v-else-if="item.id == 4"></Credits>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
            <!-- tabs content end-->
        </v-card>
    </div>
</template>

<script>
    import CategorySettings from "../components/CategorySettings"
    import UserTable from "../components/tables/UserTable"
    import restricted_domains from "../components/helper/restricted_domains"
    import Credits from "../components/Credits.vue"

    export default {
        components: {
            CategorySettings,
            UserTable,
            Credits
        },
        data() {
            return {
                info: {
                    message: null,
                    icon: null,
                    color: null
                },
                showInfo: false,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                tab: null,
                items: [
                    //tab items , dont change id -> used for template
                    { text: "User Management", id: 1 },
                    // { text: "Categories", id: 2 },
                    { text: "Account", id: 3 }
                    // { text: "Credit Overview", id: 4 }
                ],
                domains: []
            }
        },
        methods: {
            createInfo(message, color, icon) {
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },
            saveDomain(i) {
                console.log("SAVVEEE")
                console.log(this.domains[i])
                console.log(this.domainsSafe[i])

                if (this.domains[i] == this.domainsSafe[i]) return
                console.log(restricted_domains)
                if (this.domains[i].indexOf("www") > -1 || this.domains[i].indexOf("http") > -1) {
                    this.domains = JSON.parse(JSON.stringify(this.domainsSafe))
                    this.createInfo("Please enter a domain without www, http or https.", "#fcba03", "mdi-alert-circle-outline")
                    return
                }
                if (restricted_domains.domains.indexOf(this.domains[i]) == -1) {
                    this.$http
                        .post(
                            process.env.VUE_APP_APIURL + "/company/domains/change",
                            { company_id: localStorage.company_id, index: i, new_domain: this.domains[i] },
                            { headers: { Authorization: this.auth } }
                        )
                        .then((response) => {
                            console.log(response.body)
                            if (response.body.domain_registered) {
                                this.createInfo("Domain changed!", "green", "mdi-check-circle-outline")
                                this.domainsSafe = JSON.parse(JSON.stringify(this.domains))
                            } else {
                                this.createInfo("This domain is already in use! For further assistance, please contact the support.", "#fcba03", "mdi-alert-circle-outline")
                                this.domains = JSON.parse(JSON.stringify(this.domainsSafe))
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    this.domains = JSON.parse(JSON.stringify(this.domainsSafe))
                    this.createInfo("This domain is not allowed! For further assistance, please contact the support.", "#fcba03", "mdi-alert-circle-outline")
                }
            },
            fetchCompanyDomains() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/company/domains", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        console.log(response.body)
                        this.domains = JSON.parse(response.body.verification_domain)
                        this.domainsSafe = JSON.parse(response.body.verification_domain)
                        console.log(this.domains)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        mounted() {
            this.fetchCompanyDomains()

            //test end check
            if (this.$store.state.company_status === "test_ended") {
                this.$router.push("/endoftest")
            }
        }
    }
</script>
<style scoped>
    .tabheading {
        padding-top: 1px;
        padding-left: 4px;
        font-size: 24px;
    }
</style>
